@font-face {
  font-family: "Tilda Sans";
  src: url("./fonts/TildaSans-Medium.eot");
  src: local("Tilda Sans Medium"), local("TildaSans-Medium"),
    url("./fonts/TildaSans-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TildaSans-Medium.woff2") format("woff2"),
    url("./fonts/TildaSans-Medium.woff") format("woff"),
    url("./fonts/TildaSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tilda Sans";
  src: url("./fonts/TildaSans-Regular.eot");
  src: local("Tilda Sans"), local("TildaSans-Regular"),
    url("./fonts/TildaSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/TildaSans-Regular.woff2") format("woff2"),
    url("./fonts/TildaSans-Regular.woff") format("woff"),
    url("./fonts/TildaSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "TildsSans", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
  border-style: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0d0d0d;
}

code {
  font-family: "Poppins", sans-serif;
}
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #d32dbb;
}
